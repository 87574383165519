
import {
  modalController,
  popoverController,
  loadingController,
} from "@ionic/vue";
import { defineComponent } from "vue";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import { useI18n } from "vue-i18n";
import LangHelper from "@/utils/LangHelper";
export default defineComponent({
  name: "Language",
  components: {
    Header,
  },
  data() {
    return {
      list: new Array(0),
      languageId: "",
      isLoding: false,
    };
  },
  created() {
    this.initData();
  },

  methods: {
    initData() {
      this.list = [
        {
          name: "my.language.cn",
          id: "cn",
        },
        {
          name:"my.language.en",
          id: "en",
        },
        {
          name: "my.language.de",
          id: "de",
        },
      ];
      this.languageId = LangHelper.lang();
    },
    async changeLanguage(val: any) {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: this.$t("my.language.switch"),
        duration: 600,
      });
      await loading.present();
      setTimeout(() => {
        localStorage.removeItem("localLang");
        localStorage.setItem("localLang", val);
        this.languageId = val;
        this.$i18n.locale = val;
        loading.dismiss();
      }, 600);
    },
  },
});
